<template>
  <div v-if="details">
    <router-link :to="{name: 'software'}">
      <strong style="font-size:12px;">« вернуться назад</strong>
    </router-link>
    <br/><br/>
    <img :src="details.img" align="right" class="mt-10"/>
    <div v-html="details.description.full" class="mt-5" />
    <br/><br/>
    <router-link :to="{name: 'software'}">
      <strong style="font-size:12px;">« вернуться назад</strong>
    </router-link>
  </div>
  <div v-else-if="subscribeInProgress" style="text-align: center;">
    <loading-spinner />
  </div>
  <div v-else-if="subscribe.id">
    <div v-if="$user().software.length">
      <h3>Вы не можете оформить новую подписку пока у вас есть аткивные подписки</h3>
      <a href="#" @click.prevent="subscribe = {}">Вернуться к подпискам </a>
    </div>
    <div v-else>
      <h2>
        Подтверждение подписки на {{ subscribe.name }}
      </h2>
      <div style="font-size:14px;text-align:center">
        Необходимо подтверждение подписки. <br/>После подтверждения вы получите доступ к ключу активации.
      </div>
      <software-agreement v-if="showAgreement" />
      <div style="text-align:center;margin-top: 20px">
        <label for="agreement">
          <input type="checkbox" id="agreement" v-model="subscribeAgree">
          Я согласен с <a href="#" @click.prevent="showAgreement = !showAgreement" style="color: blue">условиями</a> договора оферты
        </label>
        <div style="padding:20px">
          <button :disabled="!subscribeAgree" @click="subscribeConfirm">Да, я подтверждаю подписку</button>
          <button @click="subscribe = {}">Нет, я передумал(а)</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="warn-common">
      <span class="warn-message warn-message-info" v-if="successMsg">
        <span v-html="successMsg" />
      </span>
      <span class="warn-message warn-message-error" v-if="errorMsg">
        Ошибка:
        <span>{{ errorMsg }}</span>
      </span>
    </div>

    <div class="left_side">
      <h2>Ваши текущие подписки</h2>
      <table class="kss_mysub" cellspacing="1" width="100%" v-for="v in $user().software" :key="v.id">
        <tr>
          <td colspan="2" class="prog_name">
            {{ v.name }}
          </td>
        </tr>
        <tr v-if="v.pd_link_win">
          <td colspan="2" class="prog_name" style="text-align: center;">
            <form :action="v.pd_link_win" target="_blank" method="GET">
              <input type="submit" value="Скачать антивирус с настроенным ключом активации"
                style="padding: 15px;white-space: pre-line;width: 20em;font-weight: bold;margin: 15px auto;"
              />
            </form>
          </td>
        </tr>
        <tr>
          <th>Защита на</th>
          <td width="210px">
            {{ v.license_count }} ПК
          </td>
        </tr>
        <tr v-if="v.cost">
          <th>Абонентская плата</th>
          <td v-if="v.is_trial"><strong>бесплатно до {{ v.trial_end_date }}</strong></td>
          <td v-else>{{ v.cost }}  руб. {{ v.cost_type === 'monthly' ? 'в месяц' : v.cost_type === 'daily' ? 'в день' : '' }}</td>
        </tr>
        <tr v-if="v.link_date">
          <th>Дата начала подписки</th>
          <td>
            {{ dateFormat(v.link_date, 'dd.mm.yyyy HH:MM') }}
          </td>
        </tr>
        <tr v-if="v.status === 'unlinking' || !(v.product_key || v.login)">
          <th>Состояние подписки</th>
          <td>
            {{ v.status === 'unlinking' ? 'отменяется' : 'в процессе' }}
          </td>
        </tr>
        <template v-else>
          <tr v-if="v.login">
            <th>Логин</th>
            <td class="activation_code">{{ v.login }}</td>
          </tr>
          <tr v-if="v.password">
            <th>Пароль</th>
            <td class="activation_code">{{ v.password }}</td>
          </tr>
          <tr v-if="v.product_key">
            <th>Код активации</th>
            <td class="activation_code">{{ v.product_key }}</td>
          </tr>
          <tr>
            <td colspan="2" style="text-align: right; padding-right: 10px">
              <a @click="unsubscribe(v.id)" href="#" style="font: bold 13px Arial;">Отменить подписку &raquo;</a>
            </td>
          </tr>

          <tr>
            <td colspan="2" class="terminate"></td>
          </tr>
        </template>
      </table>
      <em style="margin-left: 10px" v-if="!$user().software.length">На данный момент у вас нет активных подписок</em>
    </div>
    <div class="right_side">
      <h2>Описание подписок</h2>
      <div>
        <ul>
          <li>Одновременно может быть активной только одна подписка</li>
          <li>Для смены типа подписки, вам необходимо отменить текущую</li>
          <li>После отмены подписки, возможность начать новую появится только в 7 утра следующего дня</li>
          <li>
              Ежедневные списания по подписке прекращаются при наступление блокировки лицевого счёта.
              Подписка переходит в состояние паузы и будет автоматически возобновлена по выходу из блокировки.
          </li>
        </ul>
      </div>
    </div>
    <h2 style="clear: both; text-align: center;">Выберите тип подписки</h2>
    <div style="text-align: center; margin-top: 10px;" v-if="loading">
      <loading-spinner />
    </div>
    <div v-else>
      <div class="left_side">
        <software-card
          v-for="software in list.filter(s => s.column === 'left')"
          :key="software.id"
          :software="software"
          @show-confirm="showSubscribeConfirm($event)"
        />
      </div>
      <div class="right_side">
        <software-card
          v-for="software in list.filter(s => s.column === 'right')"
          :key="software.id"
          :software="software"
          @show-confirm="showSubscribeConfirm($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SoftwareAgreement from '@/components/SoftwareAgreement'
import SoftwareCard from '@/components/SoftwareCard'
import dateFormat from 'dateformat'
import api from '@/api'

export default {
  components: { SoftwareAgreement, SoftwareCard },
  data() {
    return {
      loading: true,
      list: [],
      confirmSubscribe: false,
      subscribe: {},
      subscribeAgree: false,
      showAgreement: false,
      successMsg: '',
      errorMsg: '',
    }
  },
  computed: {
    details() {
      return this.list.find(software => software.id == this.$route.params.detailsId)
    },
    subscribeInProgress() {
      return this.$store.getters.hasActiveAction(['Service.Link.Software', 'Service.Unlink.Software'])
    }
  },
  watch: {
    $route() {
      window.scrollTo(0, 0)
    }
  },
  created() {
    this.refreshList()
  },
  methods: {
    refreshList() {
      this.loading = true
      api.softwareList()
        .then(list => this.list = list)
        .then(() => this.loading = false)
    },
    dateFormat(timestamp, format) {
      return dateFormat(new Date(timestamp * 1000), format)
    },
    showSubscribeConfirm(id) {
      this.subscribe = this.list.find(software => software.id === id)
      this.subscribeAgree = false
      this.showAgreement = false
      this.confirmSubscribe = true
    },
    subscribeConfirm() {
      const subscribeId = this.subscribe.id
      this.subscribe = {}
      this.successMsg = ''
      this.errorMsg = ''
      this.$store.commit('START_ACTIVE_ACTION', 'Service.Link.Software')
      api.softwareSubscribe(subscribeId)
        .then(res => {
          if (res.success)  {
            this.successMsg = 'Подписка будет <b>оформлена</b> в течение нескольких минут'
          } else {
            return res.message
          }
        })
        .catch(() => 'Ошибка сервера')
        .then(err => {
          if (err) {
            this.errorMsg = err
            this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Service.Link.Software')
          }
        })
    },
    unsubscribe(id) {
      this.successMsg = ''
      this.errorMsg = ''
      if (!window.confirm('Вы действительно хотите отказаться от текущей подписки ?')) {
        return
      }
      this.$store.commit('START_ACTIVE_ACTION', 'Service.Unlink.Software')
      api.softwareUnsubscribe(id)
        .then(res => {
          if (res.success)  {
            this.successMsg = 'Подписка будет <b>отменена</b> в течение нескольких минут'
            setTimeout(() => {
              this.$auth.fetch()
              this.refreshList()
            }, 5000)
          } else {
            return res.message
          }
        })
        .catch(() => 'Ошибка сервера')
        .then(err => {
          if (err) {
            this.errorMsg = err
            this.$store.commit('COMPLETE_ACTIVE_ACTION', 'Service.Unlink.Software')
          }
        })
    }
  },
  ws: {
    private: {
      CompleteAction(e) {
        const events = ['Service.Link.Software', 'Service.Unlink.Software']
        if (events.includes(e.name)) {
          this.refreshList()
        }
      }
    }
  }
}
</script>

<style scoped>
.text_msg{font: 17px Verdana, Arial, Tahoma}

.form{margin: 0;padding: 0}
.left_side {width:420px;float: left; margin-right: 10px;}
.right_side {width:420px;float: right;}

br.c{clear: both;font-size: 1px;height:0px}
span.ra{font-size:14px}

.kss_mysub{color:#444444 !important; font: 11px Tahoma,Arial !important}
.kss_mysub td, .kss_mysub th{border:1px solid #BBBBBB;height: 24px;padding-left: 10px;text-align: left;}
.kss_mysub th{text-align: right;padding-right: 10px;}
.kss_mysub .activation_code,.activation_code{color:black;padding:3px 5px;font:bold 15px 'Courier New', Courier, monospace;white-space: nowrap}
.kss_mysub .prog_name{color:#c0240c;font-size:14px;font-weight: bold; height: 28px}
.kss_mysub .terminate{border:0;text-align: right;padding-right:10px}
.kss_mysub .terminate a{font:bold 13px Arial;border:0;text-decoration: underline}

.kss_msg{border: 1px dashed green;background: #ddffdd;padding: 10px;font-weight: bold;color:green}
.kss_msg.fail{border-color:#cc0000;background: #ffdddd;color:#cc0000}
</style>
