<template>
  <div style="height: 100;">
    <table border="0" cellspacing="0" cellpadding="0" style="margin: 200px auto 0 auto; width: 250px !important">
      <tr>
        <td style="text-align: center; vertical-align: top; margin-bottom: 10px;">
          <img v-svg-inline src="@/assets/img/atk-logo-login.svg" class="login-logo" style="height: 100px;" alt="Вход в &quot;Личный кабинет&quot;" />
        </td>
      </tr>
      <tr>
        <td style="padding-top: 10px;">
          <form id="form" name="form" @submit.prevent="doLogin">
            <div>
              <input v-model="login" name="login_input" type="text" id="login_input" placeholder="Номер лицевого счета или логин" />
            </div>
            <div>
              <input v-model="password" name="pwd_input" type="password" id="pwd_input" class="login-input" placeholder="Пароль" />
            </div>
            <div style="text-align:center; margin-top: 2px;">
              <loading-btn class="button-enter" name="Enter" type="submit" :loading="loading">Войти</loading-btn>
            </div>
          </form>
        </td>
      </tr>
    </table>
    <div class="validation-error">
      {{ errorMsg }}
    </div>
    <div style="font:normal 11px Verdana, Arial, Helvetica, sans-serif; text-align:center;padding-top:5px;">
      © ООО <a href="http://inetvl.ru">&laquo;ОктопусНет&raquo;</a> 2007-{{ currentYear }}
    </div>
    <div style="text-align:center; margin: 10px auto; width: 256px;" v-if="needSocialLogin">
      <button class="button-enter button-enter-soc" type="button" @click="doSocialLogin">
        Социальный интернет
      </button>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      login: '',
      password: '',
      loading: false,
      errorMsg: '',
      needSocialLogin: false,
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
  created() {
    this.$store.commit('CLEAR')
    api.preLogin().then((data) => this.needSocialLogin = !!data.needSocialLogin)
  },
  methods: {
    doLogin() {
      this.loading = true
      this.errorMsg = ''
      const authRedirect = this.$auth.redirect()
      const redirect = authRedirect ? authRedirect.from.path : '/'
      this.$auth.login({
        data: {username: this.login, password: this.password},
        redirect,
      }).catch(err => {
        const res = err.response || {}
        switch (res.status) {
          case undefined:
            this.errorMsg = 'Проблема с сетью'
            break
          case 401:
            this.errorMsg = 'Неправильный логин или пароль'
            break
          case 429:
            this.errorMsg = 'Слишком частые запросы, попробуйте позже'
            break
          default:
            this.errorMsg = 'Неизвестная ошибка'
        }
        this.loading = false
      })
    },
    doSocialLogin() {
      location = 'http://gosfin.inetvl.ru/social'
    },
  }
}
</script>

<style lang="scss" scoped>
/** Logo hover */
.login-logo {
  :deep(#path44), :deep(#path14), :deep(#path16) {transition: fill ease-in-out 0.1s;}
  :deep(#path44:hover) {fill: #FFD501;}
  :deep(#path14:hover) {fill: #983EC5;}
  :deep(#path16:hover) {fill: #FF3C4C;}
}

.button-enter {
  width: 100%;
  border: none;
  background-color: #EE2D36;
  padding: 5px 0;
  font-size: 15px;
  color: #FFFFFF;
  font-weight: bold;

  &:hover {background-color: #6C4099;}
  &:active {background-color: #FDB813;}
  &:disabled {background-color: #aaa;}
  &-soc {
    background-color: #6080c8;
  }
  &-soc:hover {
    background-color: #7095e6;
  }
}

#login_input, #pwd_input { width: 250px; height: 20px; padding: 0 2px; margin: 4px 0; border: 1px solid #ccc; background: #fffef5; }
.placeholder {font-style: italic; color: #CCCCCC;}
a {color: #0D71C8;}
a:hover {color: #0D71C8; border: 0;}
</style>
