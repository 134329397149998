<template>
  <div>
    <div style="display: flex;">
      <button :disabled="loading" @click="makeRequest('avail-opts')">Доступные опции</button>
      <button :disabled="loading" @click="makeRequest('linked-opts')">Подключенные услуги</button>
    </div>
    <pre style="margin-top: 20px; overflow: scroll;">{{ output }}</pre>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      loading: false,
      output: ''
    }
  },
  methods: {
    makeRequest(type) {
      this.output = ''
      this.loading = true
      axios.post('/elk/debug', {type})
        .then((res) => this.output = res.data)
        .finally(() => this.loading = false)
    }
  },
}
</script>
