import { render, staticRenderFns } from "./Block.vue?vue&type=template&id=c26fe528&scoped=true&"
import script from "./Block.vue?vue&type=script&lang=js&"
export * from "./Block.vue?vue&type=script&lang=js&"
import style0 from "./Block.vue?vue&type=style&index=0&id=c26fe528&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c26fe528",
  null
  
)

export default component.exports