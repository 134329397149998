import axios from 'axios'

axios.defaults.baseURL = '/api'
axios.defaults.params = { ts: Date.now() }

export default {
  preLogin() {
    return axios.get('/auth/prelogin').then(res => res.data)
  },
  paymentMobile(summa, phone) {
    return axios.post('/payment/mobile', {summa, phone}).then(res => res.data)
  },
  paymentCard(summa, email) {
    return axios.post('/payment/card', {summa, email}).then(res => res.data)
  },
  activateInet() {
    return axios.get('/user/activate-inet').then(res => res.data)
  },
  changePassword(oldPassword, newPassword) {
    return axios.post('/user/change-password', {oldPassword, newPassword}).then(res => res.data)
  },
  linkMac(confirmed) {
    return axios.post('/user/link-mac', {confirmed}).then(res => res.data)
  },
  getStreets() {
    return axios.get('/address/streets').then(res => res.data)
  },
  addressMovement(city, street, house, flat, phone) {
    return axios.post('/address/movement', {city, street, house, flat, phone}).then(res => res.data)
  },
  connectFriend(city, street, house, flat, fio, phone) {
    return axios.post('/tariff/friends', {city, street, house, flat, fio, phone}).then(res => res.data)
  },
  promoCode(code) {
    return axios.post('/user/promo-code', {code}).then(res => res.data)
  },
  blockToggle(dateFrom, dateTo) {
    return axios.post('/user/block-toggle', {dateFrom, dateTo}).then(res => res.data)
  },
  softwareList() {
    return axios.get('/software/list').then(res => res.data)
  },
  softwareSubscribe(id) {
    return axios.post('/software/subscribe', {id}).then(res => res.data)
  },
  softwareUnsubscribe(id) {
    return axios.post('/software/unsubscribe', {id}).then(res => res.data)
  },
  glazAuth() {
    return axios.get('/user/glaz-auth').then(res => res.data)
  },
  getTariffAbonements() {
    return axios.get('/tariff/abonements').then(res => res.data || [])
  },
  getUserContacts() {
    return axios.get('/user/contacts').then(res => res.data)
  },
  saveUserContacts(email, phone, mobile, notify, sms_verify_code) {
    return axios.post('/user/contacts', {email, phone, mobile, notify, sms_verify_code}).then(res => res.data)
  },
  serviceList() {
    return axios.get('/service/list').then(res => res.data)
  },
  serviceLink(serviceId) {
    return axios.post('/service/link', {serviceId}).then(res => res.data)
  },
  serviceUnlink(linkId) {
    return axios.post('/service/unlink', {linkId}).then(res => res.data)
  },
  whiteIpToggle() {
    return axios.post('/service/white-ip-toggle').then(res => res.data)
  },
  socialInetToggle() {
    return axios.post('/service/social-inet-toggle').then(res => res.data)
  },
  getReportTypes() {
    return axios.get('/reports/type-list').then(res => res.data)
  },
  getReport(type, dateFrom, dateTo, filter) {
    return axios.post('/reports/get-report', {type, dateFrom, dateTo, filter}).then(res => res.data)
  },
  tariffsAvailForChange() {
    return axios.get('/tariff/avail-for-change').then(res => res.data)
  },
  tariffSetNext(tariffId) {
    return axios.post('/tariff/set-next', {tariffId}).then(res => res.data)
  },
  tariffSetCurrent(tariffId) {
    return axios.post('/tariff/set-current', {tariffId}).then(res => res.data)
  },
  gameFetchData() {
    return axios.get('/game/fetch-data').then(res => res.data)
  },
  gameLinkWg() {
    return axios.get('/game/link-wg').then(res => res.data)
  },
  gameUnlnk(platform) {
    return axios.post('/game/unlink', {platform}).then(res => res.data)
  },
  gameActivateBonus(platform) {
    return axios.post('/game/bonus/activate', {platform}).then(res => res.data)
  },
  gameDeactivateBonus(platform) {
    return axios.post('/game/bonus/deactivate', {platform}).then(res => res.data)
  },
  esppResult(srcPayId) {
    return axios.post('/espp/result', {srcPayId}).then(res => res.data)
  },
  esppSaveCard(srcPayId) {
    return axios.post('/espp/save-card', {srcPayId}).then(res => res.data)
  },
  esspCardsList() {
    return axios.get('/espp/cards-list').then(res => res.data)
  },
  esppAutopayToggle(esppCardId) {
    return axios.post('/espp/autopay-toggle', {esppCardId}).then(res => res.data)
  },
  esppDeleteCard(esppCardId) {
    return axios.post('/espp/delete-card', {esppCardId}).then(res => res.data)
  },
}
