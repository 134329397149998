<template>
  <div>
    <br/>
    <div>Текущий тариф: <b>{{ $user().tariff.title }}</b></div>
    <div>Абонентская плата: <b>{{ $user().tariff.params.cost }}</b> руб./мес.</div>
    <div>Интернет: <b>{{ Math.floor($user().tariff.params.speed / 1024) }}</b> Мбит/с.</div>

    <div style="text-align: center" v-if="loading"><loading-spinner /></div>
    <form @submit.prevent="abonLink" v-else-if="abonements.length">
      <div v-if="speedAbons.length">
        <br/>
        <h4>Увеличьте скорость Интернета до 100 Мбит/с</h4>
        <p>Оплатите абонентскую плату вашего тарифа за 2, 4 или 6 месяцев вперед и скорость вашего тарифа увеличится до 100 Мб/с на срок предоплаченного периода.</p>
        <hr/>
        <table class="abonement-table">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>Ускорение</td>
              <td>Скорость с ускорением</td>
              <td>Оплатить</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="abon in speedAbons" :key="abon.id">
              <td><input type="radio" name="abon_id" v-model="selectedId" :disabled="linking" :value="abon.id" :id="`prd-${abon.id}`"></td>
              <td :class="`fg-${abon.duration}`">
                <label :for="`prd-${abon.id}`">{{ abon.name }}</label>
              </td>
              <td>
                <label :for="`prd-${abon.id}`">+{{ Math.floor(abon.speed_bonus / 1024) }} Мбит/с</label>
              </td>
              <td :class="`fg-${abon.duration}`">
                <label :for="`prd-${abon.id}`">{{ Math.floor(($user().tariff.params.speed + abon.speed_bonus) / 1024) }} Мбит/с</label>
              </td>
              <td :class="`fg-${abon.duration}`">
                <label :for="`prd-${abon.id}`">{{ abon.cost }} руб.</label>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="costAbons.length">
        <br/><br/>
        <h4>Экономьте до 15% на абонентской плате ежемесячно</h4>
        <hr/>
        <table class="abonement-table">
          <thead>
            <tr>
              <td></td>
              <td></td>
              <td>Стоимость без скидки</td>
              <td>Стоимость со скидкой</td>
              <td>Экономия</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="abon in costAbons" :key="abon.id">
              <td><input type="radio" name="abon_id" v-model="selectedId" :disabled="linking" :value="abon.id" :id="`prd-${abon.id}`"></td>
              <td>
                <label :for="`prd-${abon.id}`">{{ abon.name }}</label>
              </td>
              <td>
                <label :for="`prd-${abon.id}`">{{ $user().tariff.params.cost * abon.duration }} руб.</label>
              </td>
              <td>
                <label :for="`prd-${abon.id}`">{{ abon.cost }} руб.</label>
              </td>
              <td :class="`fg-${abon.duration}`">
                <label :for="`prd-${abon.id}`">
                  {{ $user().tariff.params.cost * abon.duration - abon.cost }} руб.
                  ({{ Math.round(($user().tariff.params.cost * abon.duration - abon.cost) / ($user().tariff.params.cost * abon.duration) * 100) }}%)
                </label>
              </td>
            </tr>
          </tbody>
        </table>
        <hr/>
      </div>
      <br/><br/>
      <div style="font-size: 110%">
        На момент подключения услуги Абонемент баланс вашего Лицевого счета должен быть на
        <b>50 руб. больше</b> стоимости выбранного абонемента во избежание возможной блокировки услуг.
      </div>
      <div style="font-weight: bold; margin: 25px 0">
        <input type="checkbox" name="agree" id="agree" v-model="acceptTerms" :disabled="linking" />
        <label for="agree">С <a style="font-weight: bold; color: #05a3d3" href="#" @click.prevent="showTerms = true">условиями предоставления услуги</a> "Абонемент" ознакомлен и согласен</label>
      </div>
      <div style="text-align: center">
        <loading-btn :loading="linking" :disabled="!selectedId || !acceptTerms" type="submit" style="min-width: 230px; padding:10px 30px">
          Подключить абонемент
        </loading-btn>
      </div>
      <br/>
      <h1 class="warn-message-error" style="text-align: center">
        {{ linkErrorText }}
      </h1>
      <br/><br/><br/>
    </form>

    <div class="overlay" v-if="showTerms">
      <div class="modal">
        <h1 class="modal-title">
          Условия предоставления услуги Абонемент
        </h1>
        <hr/>
        <ul>
          <li>Баланс лицевого счета Абонента на момент подключения им услуги "Абонемент" больше стоимости выбранного абонемента с тем, чтобы после списания стоимости абонемента баланс лицевого счета был больше нуля, чтобы не допустить блокировку услуг по причине нулевого баланса.</li>
          <li>Списание стоимости выбранного Абонентом абонемента производится в размере 100% стоимости абонемента единовременно при подтверждении Абонентом заказа услуги «Абонемент» через личный кабинет Абонента либо абонентские отделы Оператора.</li>
          <li>В течение срока действия абонемента следующие действия приводят к потере скидки, предусмотренной абонементом, и стоимости подключенного абонемента:</li>
          <ul>
            <li>расторжение Договора на оказание услуг связи Оператора до истечения срока действия абонемента;</li>
            <li>добровольная блокировка лицевого счета абонента;</li>
            <li>переезд Абонента в период действия абонемента в другое помещение по адресу, где отсутствует техническая возможность одновременного предоставления Оператором телематических услуг связи и услуг связи для целей кабельного вещания и поэтому невозможно предоставление услуг связи на условиях тарифных планов, по которым услуга "Абонемент" возможна.</li>
          </ul>
          <li>Абонемент начинает действие в момент активации услуги Абонентом через личный кабинет Абонента либо абонентские отделы Оператора.</li>
          <li>В период действия абонемента начисление абонентской платы тарифного плана Абонента приостанавливается и возобновляется после завершения периода действия абонемента.</li>
          <template v-if="$auth.check('ROLE_ATK')">
            <li>На один лицевой счет может быть последовательно и одновременно подключено более одного абонемента. При этом старт второго и последующего абонементов начинается с момента окончания первого и предыдущего абонементов.</li>
            <li>В случае смены текущего тарифа на новый, в течение срока действия абонемента, происходит перерасчет срока действия предоплаченного периода, при этом размер скидки, предусмотренной абонементом, сохраняется и действует на абонентскую плату нового тарифа.</li>
          </template>
        </ul>
        <hr/>
        <div style="text-align: right">
          <button @click="showTerms = false">Ok</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  data() {
    return {
      loading: true,
      abonements: [],
      selectedId: null,
      acceptTerms: false,
      showTerms: false,
      linkErrorText: '',
    }
  },
  computed: {
    speedAbons() {
      return this.abonements.filter(a => a.speed_bonus)
    },
    costAbons() {
      return this.abonements.filter(a => !a.speed_bonus)
    },
    linking() {
      return this.$store.getters.hasActiveAction(['Service.Link.Abonement', 'Service.Pending.Abonement'])
    },
  },
  created() {
    api.getTariffAbonements()
      .then(res => this.abonements = res.filter(a => a.available_for_user).sort((a, b) => a.duration - b.duration))
      .finally(() => this.loading = false)
  },
  methods: {
    abonLink() {
      const actName = this.$user().discount ? 'Service.Pending.Abonement' : 'Service.Link.Abonement'
      this.$store.commit('START_ACTIVE_ACTION', actName)
      this.linkErrorText = ''

      api.serviceLink(this.selectedId)
        .catch(() => ({message: 'Произошла системная ошибка'}))
        .then(res => {
          if (!res.success) {
            this.linkErrorText = res.message
            this.$store.commit('COMPLETE_ACTIVE_ACTION', actName)
          }
        })
    },
  },
  ws: {
    private: {
      CompleteAction() {
        this.$router.push({name: 'profile'})
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .abonement-table {
    width: 100%;
    border-collapse: collapse;
    td {
      padding: 5px 0;
    }
    tbody td {
      border-top: 1px solid #444;
      label {
        display: block;
      }
    }
    .fg-2, .fg-4, .fg-6 {
      font-weight: bold;
    }
    .fg-4, .fg-4 label {
      color: green;
    }
    .fg-6, .fg-6 label {
      color: red;
    }
  }
  .overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(33, 33, 33, .3);
    .modal {
      width: 750px;
      padding: 10px;
      background-color: #fff;
      border: 1px solid #aaa;
      border-radius: 4px;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>
